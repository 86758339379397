<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Header, Form },
  computed: {
    ...mapState("legal_people", ["legal_person"]),
  },
  methods: {
    ...mapActions("legal_people", ["ActionShow"]),
    async loadData(id) {
      await this.ActionShow({ id: id });
    },
  },
  async created() {
    await this.loadData(this.$route.params.id);
  },
};
</script>

<style>
</style>
